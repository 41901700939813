import {Link} from "react-router-dom";

const Footer = () => {
    const services = [
        {name: "خدمات 1", link: "#"},
        {name: "خدمات 2", link: "#"},
        {name: "خدمات 3", link: "#"},
        {name: "خدمات 4", link: "#"},
    ];

    const socialLinks = [
        {
            name: "Facebook",
            iconPath: "M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
        },
        {
            name: "Instagram",
            iconPath: "M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
        },
        // Add more social links as needed
    ];
    return (
        <footer className="bg-[#2B3742] mt-[300px]">

            <div className="container flex flex-col items-center justify-start">


                <div
                    className="bg-primary flex md:flex-row flex-col max-md:gap-5 items-center justify-between w-full  md:w-[1100px] rounded-[32px] p-5 -mt-12">
                    <div className="text-xl md:text-2xl font-bold text-white pt-1">پاسخگو پرسش های شما هستیم...</div>

                    <div className=" flex justify-between items-center gap-3">


                        <div className="text-xl md:text-2xl pt-3 text-white">۶۳۰۳۹۱۹ ۰۷۱</div>

                        <div className="w-0.5  h-10 bg-gray-50 opacity-50"></div>


                        <div
                            className="bg-white p-2 pt-[18px] rounded-[20px] flex items-center justify-center text-xl md:text-2xl font-bold text-primary">درخواست
                            مشاوره
                        </div>


                    </div>
                </div>


            </div>

            {/* btm  */}


            <div class="mx-auto max-w-screen-xl space-y-8 px-4 py-16 pt-32 sm:px-6 lg:space-y-16 lg:px-8 ">
                <div class="sm:flex sm:items-center sm:justify-between">
                    <div class="text-gray-400 flex flex-col max-w-[400px] text-sm text-right">
                        متریر
                        <p> متریر با هدف تسهیل سرمایه‌گذاری در املاک و فراهم آوردن اطلاعات شفاف و ابزارهای نوین،
                            فضایی مطمئن و قابل اعتماد برای سرمایه‌گذاران ایجاد می‌کند.


                        </p>
                    </div>

                    <ul class="mt-8 flex justify-start gap-6 sm:mt-0 sm:justify-end">
                        {socialLinks.map(({name, iconPath}, index) => (
                            <li key={index}>
                                <a
                                    href="#"
                                    rel="noreferrer"
                                    target="_blank"
                                    className="text-gray-700 transition hover:opacity-75 !text-gray-200"
                                >
                                    <span className="sr-only">{name}</span>
                                    <svg className="size-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                                        <path d={iconPath}/>
                                    </svg>
                                </a>
                            </li>
                        ))}
                    </ul>
                </div>

                <div
                    class="grid grid-cols-1 gap-8 border-t border-gray-100 pt-8 sm:grid-cols-2 lg:grid-cols-4 lg:pt-16 !border-gray-800 text-right"
                >
                    <div>
                        <p class="font-medium text-gray-900 !text-white">خدمات</p>

                        <ul class="mt-6 space-y-4 text-sm">
                            {services.map(({name, link}, index) => (
                                <li key={index}>
                                    <a href={link}
                                       className="text-gray-700 transition hover:opacity-75 !text-gray-200">{name}</a>
                                </li>
                            ))}


                        </ul>
                    </div>

                    <div>
                        <p class="font-medium text-gray-900 !text-white">صفحات</p>

                        <ul class="mt-6 space-y-4 text-sm">
                            <li>
                                <a href="#" class="text-gray-700 transition hover:opacity-75 !text-gray-200">
                                    درباره ما
                                </a>
                            </li>

                            <li>
                                <a href="#" class="text-gray-700 transition hover:opacity-75 !text-gray-200">
                                    ارتباط با ما
                                </a>
                            </li>

                            <li>
                                <a href="#" class="text-gray-700 transition hover:opacity-75 !text-gray-200">
                                    شعب فعال
                                </a>
                            </li>
                        </ul>
                    </div>


                    <div className=" col-span-2">
                        <p class="font-medium text-gray-900 !text-white">دفتر ما</p>

                        <div
                            className="bg-[#212a33] flex flex-col justify-center items-start text-gray-300 p-4 gap-3 rounded-2xl">

                            <div className="pr-2">دفتر شیراز</div>

                            <div className="flex items-center">
                                <span className="text-gray-500">تلفن:</span>
                                <span className="pr-6">۰۷۱۳۶۳۰۳۹۱۹</span>
                            </div>
                            <div className="flex items-center">
                                <span className="text-gray-500">آدرس:</span>
                                <span className="pr-6">شیراز - محلاتی - کوچه ۲ - پلاک ۳۶ - واحد ۱</span>
                            </div>
                            <a referrerPolicy='origin' target='_blank'
                               href='https://trustseal.enamad.ir/?id=545636&Code=1ECMktihxWDNisixyDNXUaju2LfFp5B9'>
                                <img
                                referrerPolicy='origin'

                                src='https://trustseal.enamad.ir/logo.aspx?id=545636&Code=1ECMktihxWDNisixyDNXUaju2LfFp5B9'
                                alt='Enamad' style={{cursor: 'pointer'}} code='1ECMktihxWDNisixyDNXUaju2LfFp5B9'/>
                            </a>

                        </div>

                    </div>

                </div>

                <p class="text-xs text-gray-500 !text-gray-400">
                    &copy; ۲۰۲۴. تمامی حقوق مادی و معنوی برای این شرکت محفوظ است.
                </p>
            </div>


        </footer>
    )
}

export default Footer;