import "tailwindcss/tailwind.css";
// import '../App.css';
import '../styles.css';


const Hero = () => {
    return (
        <div className="bg-white">
            <div class="relative isolate overflow-hidden">
                <div
                    class="mt-[-50px] flex  items-center justify-between container md:flex-row flex-col max-md:pt-36 max-md:pb-24">
                    <div
                        class="max-w-full md:h-screen flex justify-between flex-shrink-0 px-4 text-center lg:mx-0 lg:max-w-3xl lg:pt-8">
                        <div className="pt-20 flex flex-col items-start justify-center">

                            <div
                                class="mt-10 flex flex-col text-5xl tracking-tight text-gray-700 sm:text-5xl max-w-xl text-right gap-4 font-custom font-black">
                                <span>  <span className="text-primary pl-2">راحت تر</span> از هر همیشه </span></div>

                            <p className="text-[#6a6a6a] mt-8 text-xl text-right max-w-xl opacity-80 font-custom font-medium">
                                از رو کاناپه در  <span
                                className="text-primary !text-xl font-bold">املاک</span> سرمایه گذاری کن
                            </p>

                            <div className="mt-10 flex justify-between gap-7 border rounded-2xl items-center p-6 pt-7">
                                دریافت مشاوره رایگان


                                <svg className="-mt-1" width="20px" height="100%" viewBox="0 0 24 24" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path d="M15 18L9 12L15 6" stroke="currentColor" stroke-width="2"
                                          stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </div>


                        </div>


                    </div>
                    <div className="max-md:px-5">
                        <img className="w-[500px] pt-36" src="/img/hero.png"></img>

                    </div>
                </div>
            </div>

        </div>
    )
}

export default Hero;