import React, { useState, useEffect } from "react";
import {Link} from "react-router-dom";


const Header = () => {
    const [atTop, setAtTop] = useState(true);
    const [open, setOpen] = useState(false);
  
    useEffect(() => {
      const handleScroll = () => {
        setAtTop(window.pageYOffset <= 50);
      };
      window.addEventListener("scroll", handleScroll);
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }, []);
    return (
        <header>
        <div
          className={`fixed z-50 w-full px-8 py-2 pt-4 transition-all duration-1000 rounded-3xl  md:max-w-2xl  inset-x-0 mx-auto ease-in-out transform ${
            atTop ? "md:max-w-3xl max-w-[350px] bg-white mt-3 shadow-2xl" : "bg-black bg-opacity-60 backdrop-blur-xl md:max-w-7xl max-w-[390px] mt-5"
          }`}
        >
          <div className="flex flex-row justify-between w-full p-2 mx-auto md:items-center md:justify-between md:flex-row">
            <div className="flex flex-row items-center justify-between">
              <a href="/"
                className={`font-bold tracking-tighter text-white uppercase ${
                  atTop ? "text-white" : "text-white"
                }`}
              >
                {/* <img src="/img/logov.png" className="w-[80px]"></img> */}
                <span className={`transition-all duration-1000 ${atTop ? " text-gray-500 pr-2": "text-white pr-2"}`}>لوگو</span>
              </a>
              <button className="md:hidden focus:outline-none" onClick={() => setOpen(!open)}>
                {/* SVG Burger goes here */}
              </button>
            </div>
            <nav
              className={`flex-col flex-grow gap-8 pb-4 md:pb-0 md:flex md:flex-row lg:ml-auto justify-end ${
                open ? "flex" : "hidden"
              }`}
            >
              
              

              
            </nav>

            {/* moshavere */}

            <div className="flex flex-col items-start justify-center gap-1 max-md:hidden">

              <div className={` transition-all duration-1000 ${atTop ? "text-gray-700" : "text-white"}`}>
                در ارتباط باشید
              </div>
              <div className={` transition-all duration-1000 font-bold ${atTop ? "text-gray-700" : "text-white"}`}>
                <span className={`transition-all  duration-1000 ${atTop ? " text-gray-500 pr-2": "text-white pr-2"}`}>۰۷۱</span>۶۳۰۳۹۱۹
              </div>

            </div>

            {/* moshavere end */}



          </div>
        </div>

      </header>
    )
}

export default Header;