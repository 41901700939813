const serviceData = [
    {
        title: "سرمایه‌گذاری در املاک",
        subtitle: "Real Estate Investment",
        description: "سرمایه‌گذاری در املاک با مبالغ اندک و بهره‌مندی از بازدهی اجاره به همراه رشد قیمت ملک.",
        icon: (

            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M9.02 2.83992L3.63 7.03992C2.73 7.73992 2 9.22992 2 10.3599V17.7699C2 20.0899 3.89 21.9899 6.21 21.9899H17.79C20.11 21.9899 22 20.0899 22 17.7799V10.4999C22 9.28992 21.19 7.73992 20.2 7.04992L14.02 2.71992C12.62 1.73992 10.37 1.78992 9.02 2.83992Z"
                    stroke="#ffffff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path
                    d="M10.5 18H13.5C15.15 18 16.5 16.65 16.5 15V12C16.5 10.35 15.15 9 13.5 9H10.5C8.85 9 7.5 10.35 7.5 12V15C7.5 16.65 8.85 18 10.5 18Z"
                    stroke="#ffffff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M12 9V18" stroke="#ffffff" stroke-width="1.5" stroke-linecap="round"
                      stroke-linejoin="round"/>
                <path d="M7.5 13.5H16.5" stroke="#ffffff" stroke-width="1.5" stroke-linecap="round"
                      stroke-linejoin="round"/>
            </svg>

        ),
    },
    {
        title: "مدیریت سرمایه‌گذاری",
        subtitle: "Investment Management",
        description: "امکان مدیریت ساده و بی‌واسطه‌ی سرمایه‌گذاری‌ها و پیگیری بازدهی هر ملک.",
        icon: (
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2 22H22" stroke="#ffffff" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"
                      stroke-linejoin="round"/>
                <path d="M9.75 4V22H14.25V4C14.25 2.9 13.8 2 12.45 2H11.55C10.2 2 9.75 2.9 9.75 4Z" stroke="#ffffff"
                      stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M3 10V22H7V10C7 8.9 6.6 8 5.4 8H4.6C3.4 8 3 8.9 3 10Z" stroke="#ffffff" stroke-width="1.5"
                      stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M17 15V22H21V15C21 13.9 20.6 13 19.4 13H18.6C17.4 13 17 13.9 17 15Z" stroke="#ffffff"
                      stroke-width="1.5"
                      stroke-linecap="round" stroke-linejoin="round"/>
            </svg>

        ),
    },
    {
        title: "دریافت سود اجاره",
        subtitle: "Rental Income",
        description: "دریافت منظم سود حاصل از اجاره‌ ملک‌ها بر اساس سهم سرمایه‌گذاری.",
        icon: (
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M19.3 7.91998V13.07C19.3 16.15 17.54 17.47 14.9 17.47H6.10995C5.65995 17.47 5.22996 17.43 4.82996 17.34C4.57996 17.3 4.33996 17.23 4.11996 17.15C2.61996 16.59 1.70996 15.29 1.70996 13.07V7.91998C1.70996 4.83998 3.46995 3.52002 6.10995 3.52002H14.9C17.14 3.52002 18.75 4.47001 19.18 6.64001C19.25 7.04001 19.3 7.44998 19.3 7.91998Z"
                        stroke="#ffffff" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"
                        stroke-linejoin="round"/>
                    <path
                        d="M22.3011 10.9201V16.0701C22.3011 19.1501 20.5411 20.4701 17.9011 20.4701H9.11105C8.37105 20.4701 7.70106 20.3701 7.12106 20.1501C5.93106 19.7101 5.12105 18.8001 4.83105 17.3401C5.23105 17.4301 5.66105 17.4701 6.11105 17.4701H14.9011C17.5411 17.4701 19.3011 16.1501 19.3011 13.0701V7.9201C19.3011 7.4501 19.2611 7.03014 19.1811 6.64014C21.0811 7.04014 22.3011 8.38011 22.3011 10.9201Z"
                        stroke="#ffffff" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"
                        stroke-linejoin="round"/>
                    <path
                        d="M10.4984 13.1399C11.9564 13.1399 13.1384 11.9579 13.1384 10.4999C13.1384 9.04185 11.9564 7.85986 10.4984 7.85986C9.04038 7.85986 7.8584 9.04185 7.8584 10.4999C7.8584 11.9579 9.04038 13.1399 10.4984 13.1399Z"
                        stroke="#ffffff" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"
                        stroke-linejoin="round"/>
                    <path d="M4.78003 8.30005V12.7001" stroke="#ffffff" stroke-width="1.5" stroke-miterlimit="10"
                          stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M16.2217 8.30029V12.7003" stroke="#ffffff" stroke-width="1.5" stroke-miterlimit="10"
                          stroke-linecap="round" stroke-linejoin="round"/>
                </svg>


            </svg>

        ),
    },
    {
        title: "افزایش سرمایه ملک",
        subtitle: "Property Value Growth",
        description: "بهره‌مندی از افزایش قیمت ملک در طول زمان و بالا رفتن ارزش سرمایه‌گذاری.",
        icon: (
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M8.67188 14.3298C8.67188 15.6198 9.66188 16.6598 10.8919 16.6598H13.4019C14.4719 16.6598 15.3419 15.7498 15.3419 14.6298C15.3419 13.4098 14.8119 12.9798 14.0219 12.6998L9.99187 11.2998C9.20187 11.0198 8.67188 10.5898 8.67188 9.36984C8.67188 8.24984 9.54187 7.33984 10.6119 7.33984H13.1219C14.3519 7.33984 15.3419 8.37984 15.3419 9.66984"
                    stroke="#ffffff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M12 6V18" stroke="#ffffff" stroke-width="1.5" stroke-linecap="round"
                      stroke-linejoin="round"/>
                <path
                    d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                    stroke="#ffffff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>

        ),
    },
    {
        title: "تنوع‌بخشی به سرمایه‌گذاری",
        subtitle: "Investment Diversification",
        description: "افزایش امنیت مالی از طریق سرمایه‌گذاری در چندین ملک متنوع.",
        icon: (
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2 22H22" stroke="#ffffff" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"
                      stroke-linejoin="round"/>
                <path
                    d="M2.94995 22L2.99995 9.96999C2.99995 9.35999 3.28995 8.78004 3.76995 8.40004L10.77 2.95003C11.49 2.39003 12.4999 2.39003 13.2299 2.95003L20.23 8.39003C20.72 8.77003 21 9.34999 21 9.96999V22"
                    stroke="#ffffff" stroke-width="1.5" stroke-miterlimit="10" stroke-linejoin="round"/>
                <path d="M13 17H11C10.17 17 9.5 17.67 9.5 18.5V22H14.5V18.5C14.5 17.67 13.83 17 13 17Z"
                      stroke="#ffffff" stroke-width="1.5" stroke-miterlimit="10" stroke-linejoin="round"/>
                <path
                    d="M9.5 13.75H7.5C6.95 13.75 6.5 13.3 6.5 12.75V11.25C6.5 10.7 6.95 10.25 7.5 10.25H9.5C10.05 10.25 10.5 10.7 10.5 11.25V12.75C10.5 13.3 10.05 13.75 9.5 13.75Z"
                    stroke="#ffffff" stroke-width="1.5" stroke-miterlimit="10" stroke-linejoin="round"/>
                <path
                    d="M16.5 13.75H14.5C13.95 13.75 13.5 13.3 13.5 12.75V11.25C13.5 10.7 13.95 10.25 14.5 10.25H16.5C17.05 10.25 17.5 10.7 17.5 11.25V12.75C17.5 13.3 17.05 13.75 16.5 13.75Z"
                    stroke="#ffffff" stroke-width="1.5" stroke-miterlimit="10" stroke-linejoin="round"/>
                <path d="M19.0001 7L18.9701 4H14.5701" stroke="#ffffff" stroke-width="1.5" stroke-miterlimit="10"
                      stroke-linecap="round" stroke-linejoin="round"/>
            </svg>


        ),
    },
];


const ServiceItem = ({title, subtitle, description, icon}) => (
    <article
        className="flex flex-col justify-center items-start p-7 bg-[#F9F9FB] border border-white rounded-3xl shadow-2xl">
        <div className="flex md:flex-row flex-col items-center justify-start gap-4">
            <div className="p-3 bg-primary text-white rounded-2xl">
                <svg width="50px" height="100%" viewBox="0 0 24 24" fill="none" stroke="#ffffff"
                     xmlns="http://www.w3.org/2000/svg">
                    {icon}
                </svg>
            </div>
            <h3 className="font-bold text-primary text-lg text-start">{title}</h3>
        </div>
        <p className="text-gray-500 text-right pt-8 leading-loose max-md:hidden">
            {description}
        </p>
    </article>
);

const Service = () => (
    <section>
        <div className="flex flex-col items-center pt-36 container">
            <h2 className="font-bold text-4xl mb-3 max-w-[750px] leading-normal text-gray-800">
                خدماتی که ما را <span className="text-primary">متمایز</span> می کند
            </h2>
            <p className="text-xl font-medium text-gray-500">
                سرمایه‌گذاری آسان و امن در املاک با خدمات متنوع و پشتیبانی کامل از شروع تا دریافت سود.

            </p>
            <div className="grid md:grid-cols-4 grid-cols-2 mt-14 md:gap-10 gap-5">
                {serviceData.map((item, index) => (
                    <ServiceItem key={index} {...item} />
                ))}
            </div>
        </div>
    </section>
);

export default Service;