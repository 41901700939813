import logo from './logo.svg';
import './App.css';

import Header from './comp/Header.js';
import Hero from './comp/Hero.js';
import Servis from './comp/Servis.js';
import WhyUs from './comp/WhyUs.js';
import Footer from './comp/Footer.js';

function App() {
  return (
    <div className="App">

      <Header />
      <Hero />
      <Servis />
      <WhyUs />
      <Footer />
   
    </div>
  );
}

export default App;
